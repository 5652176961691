<template>
  <div class="mt-7">
    <div class="w-3/4 max-w-authCard mx-auto">
      <img src="@/assets/img/logo.svg" width="130" class="mb-2" />
    </div>
    <div class="flex w-3/4 max-w-authCard mx-auto min-h-forgotPass">
      <img :src="cardImg" class="rounded rounded-r-none" />
      <div class="bg-white p-6">
        <p class="font-barlow font-bold text-grey-dark-2 text-l mb-6">
          Forgot Password
        </p>
        <p class="text-mdh text-grey-dark-1 mb-6">
          Not to worry. Just enter your work email address below and we will
          send you a link to set a new password.
        </p>
        <InputField
          label="Work Email"
          :onChange="onChange"
          :isTextOk="$v.email.email ? null : false"
          :errorText="errorText"
          type="text"
          class="mb-4"
        />
        <div class="flex flex-col items-center mb-13">
          <Button
            text="Send"
            type="primary"
            size="large"
            class="w-full"
            :onClick="onClickSend"
            :disabled="!$v.email.email"
          />
        </div>
        <button
          class="flex items-center focus:outline-none"
          @click="onClickBack"
        >
          <img :src="arrow" class="transform rotate-90 mr-1" />
          <p class="font-medium text-md text-blue-dark-1">Back to Sign in</p>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import debounce from "lodash/debounce";
import card from "../../../assets/img/auth/signin.png";
import arrow from "../../../assets/img/icons/arrow-down.svg";
import Button from "../../../components/Button/Button.vue";
import InputField from "../../../components/InputField/InputField.vue";
import Vue from "vue";
import Vuelidate from "vuelidate";
import { email } from "vuelidate/lib/validators";

Vue.use(Vuelidate);

export default {
  name: "ForgotPass",
  components: { Button, InputField },
  data: () => ({
    email: "",
    validEmail: null,
    errorText: "Please enter a correct email address.",
    disabledButton: true,
    cardImg: card,
    arrow,
  }),
  validations: {
    email: {
      email,
    },
  },
  methods: {
    ...mapActions({
      forgotPassword: "auth/forgotPassword",
    }),
    onClickSend() {
      this.forgotPassword({ email: this.email });
    },
    onClickBack() {
      this.$router.push("/login");
    },
    onChange: debounce(function (value) {
      this.email = value;
      this.validEmail = null;
    }, 500),
    checkEmail() {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.email).toLowerCase());
    },
  },
};
</script>
